* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  background-color: black;
}
iframe {
  pointer-events: none;
}
